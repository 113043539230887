import React from "react";
import Layout from "../../components/Layout";

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you for choosing SCR Consultants!</h1>
          <p>
            Keep an eye on your inbox, we'll be sending you a Calendly link so
            you can pick the best time for your consultation!
          </p>
        </div>
      </div>
    </section>
  </Layout>
);
